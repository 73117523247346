import { Injectable } from '@angular/core';
import {
  AuthResponse,
  ForgotPasswordPayload,
  LogInPayload,
  RefreshTokenResponse,
  ResetPasswordPayload,
  SignUpPayload,
} from '@core/models/entities/auth.models';
import { ProfileApi } from './profile.api';
import { map, Observable, of, tap } from 'rxjs';
import { HttpApi } from './http.api';
import { HttpGetOptions } from '@core/models/http.models';
import { Router } from '@angular/router';
import { AUTH_RC } from '@core/constants/routes.constants';
import { Store } from '@ngrx/store';
import { AuthActions } from '@features/auth/store';

const ACCESS_TOKEN_KEY: string = 'access_token';
const REFRESH_TOKEN_KEY: string = 'refresh_token';

@Injectable({ providedIn: 'root' })
export class AuthApi {
  constructor(
    private readonly httpApi: HttpApi,
    private profileApi: ProfileApi,
    private router: Router,
    private store: Store
  ) {}

  logIn(payload: LogInPayload): Observable<AuthResponse> {
    return this.httpApi.post<AuthResponse>(`auth/login`, payload).pipe(
      map((response: AuthResponse) => {
        this.setAccessToken(response.accessToken);
        this.setRefreshToken(response.refreshToken);
        this.profileApi.user = response.user;
        return response;
      })
    );
  }

  signUp(payload: SignUpPayload): Observable<AuthResponse> {
    return this.httpApi.post<AuthResponse>(`auth/sign-up`, payload).pipe(
      map((response: AuthResponse) => {
        this.setAccessToken(response.accessToken);
        this.setRefreshToken(response.refreshToken);
        this.profileApi.user = response.user;
        return response;
      })
    );
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    const refreshToken: string | null = this.getRefreshToken();

    return this.httpApi
      .post<RefreshTokenResponse>(`auth/refresh-access-token`, { refreshToken }, { skipSuccessMessage: true })
      .pipe(
        tap((response: RefreshTokenResponse) => {
          this.setAccessToken(response.accessToken);
          this.setRefreshToken(response.refreshToken);
        })
      );
  }

  logOut(successCallback?: () => void): Observable<boolean> {
    const onSuccess = () => {
      this.reset();
      this.router.navigate([`${AUTH_RC.root}/${AUTH_RC.children.logIn}`]);
      this.store.dispatch(AuthActions.logout());
      successCallback?.();
      return true;
    };
    if (!this.isAuthorized()) return of(onSuccess());
    return this.httpApi.post<boolean>(`auth/logout`, {}, { skipSuccessMessage: true }).pipe(
      tap({
        next: onSuccess,
        error: (err) => {
          if ([401, 402, 403].includes(err.status)) onSuccess();
        },
      })
    );
  }

  isAuthorized(): boolean {
    return !!this.getAccessToken();
  }

  forgotPassword(payload: ForgotPasswordPayload): Observable<boolean> {
    return this.httpApi.post<boolean>(`auth/forgot-password`, payload);
  }

  resetPassword(payload: ResetPasswordPayload, options?: HttpGetOptions): Observable<boolean> {
    return this.httpApi.patch<boolean>(`auth/reset-password`, payload, options);
  }

  private setAccessToken(token: string | null): void {
    if (token) return localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  private setRefreshToken(token: string | null): void {
    if (token) return localStorage.setItem(REFRESH_TOKEN_KEY, token);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  private reset(): void {
    this.setAccessToken(null);
    this.setRefreshToken(null);
    this.profileApi.user = null;
  }
}
